import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "./SignIn";
import Home from "./Home";
import Admin from "./Admin";
import HandleCognitoCallback from "./handleCognitoCallback";
import { isLoggedIn } from "./functions";

function App() {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn())

    return (
        <div className="App">
            <Routes>
                <Route path="/" exact element={<SignIn />} />
                <Route path="/home" exact element={isUserLoggedIn ? <Home /> : <SignIn />} />
                <Route path="/admin" exact element={isUserLoggedIn ? <Admin /> : <SignIn />} />
                <Route path="/callback" exact element={<HandleCognitoCallback setLoggedIn={setIsUserLoggedIn} />} />
            </Routes>
        </div>
    );
}

export default App;
