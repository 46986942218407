import axios from "axios";
import config from "./config.json"

const getTokenResponse = code => {
    return axios.post(config.token_endpoint, new URLSearchParams({
        grant_type: "authorization_code",
        client_id: config.client_id,
        code: code,
        redirect_uri: config.redirect_uri
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

const saveTokenResponseToLocalStorage = data => {
    setWithExpiry('access_token', data.access_token, 8)
    setWithExpiry('id_token', data.id_token, 8)
}

const setWithExpiry = (key, value, ttl) => {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + ttl * 3.6e+6,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = key => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() >= item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const isLoggedIn = () => getWithExpiry('access_token') !== null

const getUserInfo = () => {
    return axios.get(config.userinfo_endpoint, {
        headers: {
            "Authorization": "Bearer " + getWithExpiry('access_token')
        }
    })
}


const logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
}


export {
    getTokenResponse,
    isLoggedIn,
    saveTokenResponseToLocalStorage,
    getUserInfo,
    logout,
    getWithExpiry
}
