import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import config from "./config.json"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import logo from "./logo.png"

const theme = createTheme();

export default function SignIn() {
    const handleSubmit = () => {
        window.location.href = config.hosted_ui
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Box component="img" src={logo} />
                    {/* TODO: UPDATE MANUAL */}
                    <Typography component="h1" variant="h5">
                        nexRates™ Customer Portal RBI
                    </Typography>
                    <Box noValidate sx={{ mt: 1 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            endIcon={<LockOpenIcon />}
                            onClick={() => handleSubmit()}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}