import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import logo from "./logo.png";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { Container, Typography, AppBar, Toolbar, IconButton, Tooltip, Grid, Button } from "@mui/material";
import { logout } from "./functions";
import config from "./config.json";
import LogoutIcon from "@mui/icons-material/Logout";
import { DataGrid } from '@mui/x-data-grid';

export default function Admin() {
    let navigate = useNavigate();

    return (
        <Container disableGutters maxWidth="md" component="main" sx={{ pt: 2, pb: 2 }}>
            <AppBar position='static' sx={{ bgcolor: 'white' }} color='default' elevation={1}>
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Box component="img" src={logo} sx={{ height: 25, mr: 1 }} />
                    <Typography variant="overline" color="inherit" noWrap sx={{ flexGrow: 1, mt: 1 }}>
                        Customer Portal RBI
                    </Typography>

                    <Tooltip title="Home">
                        <IconButton sx={{ marginLeft: 1 }} onClick={() => {
                            navigate('/home', { replace: true })
                        }}>
                            <HomeIcon sx={{ color: '#00a731' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Logout">
                        <IconButton sx={{ marginLeft: 1 }} onClick={() => {
                            logout()
                            window.location.href = config.logout_endpoint
                        }}>
                            <LogoutIcon sx={{ color: '#D35400' }} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </Container>
    )
}